import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Map, Marker, TileLayer } from "react-leaflet";
import { isMobile, isSafari } from "react-device-detect";
import L from "leaflet";
import { useFormikContext } from "formik";
import { Button, Loader } from "../../components/common";
import { ReactComponent as Location } from "../../assets/icons/tools/location.svg";
import {
  RegagriFormControl,
  RegagriLabel,
  RegagriTextField,
  RegagriTooltip,
} from "../components";
import regagriApi from "../utils/regagriApi";

export const DEFAULT_POSITION = [52.231659, 21.005872];

const markerIcon = new L.Icon({
  // eslint-disable-next-line global-require
  iconUrl: require("../../assets/icons/map-marker.svg"),
  // eslint-disable-next-line global-require
  iconRetinaUrl: require("../../assets/icons/map-marker.svg"),
  iconAnchor: [15, 15],
  popupAnchor: [0, 0],
  iconSize: [34, 34],
});

const RegagriLocation = ({ typesData, setSelectedTypes, setReportData }) => {
  const { t } = useTranslation(["regagri"]);
  const [isMapVisible, setIsMapVisible] = useState(false);
  const [positionActive, setPositionActive] = useState([]);
  const [isGeoLoading, setIsGeoLoading] = useState(true);
  const [position, setPosition] = useState(DEFAULT_POSITION);
  const { setFieldValue, values } = useFormikContext();
  const [soilType, setSoilType] = useState("");

  const getSoilInfo = () => {
    regagriApi
      .carbonClimate({
        lat: positionActive[0],
        lon: positionActive[1],
      })
      .then(({ data }) => {
        setSoilType(data.soil_type);
        setReportData(prevState => {
          return {
            ...prevState,
            explorer: {
              ...prevState.explorer,
              climate: {
                length: data.season_length,
                temp: data.average_season_temperature,
              },
            },
          };
        });
      });
  };

  useEffect(() => {
    if (typesData.length > 1 && soilType !== "") {
      setFieldValue("soil", [
        {
          ...typesData.find(el => el.name === soilType),
          area:
            values.soil.length > 0 && values.soil[0].area
              ? values.soil[0].area
              : "0",
        },
      ]);
      setSelectedTypes(prevState => {
        const newSelectedTypes = [...prevState];
        newSelectedTypes[0] = {
          ...typesData.find(el => el.name === soilType),
        };
        return newSelectedTypes;
      });
    }
  }, [soilType, typesData]);

  const handleGeo = () => {
    setIsGeoLoading(true);
    const onLocationFetchSuccess = pos => {
      const { latitude, longitude } = pos.coords;
      setPositionActive([latitude, longitude]);
      setPosition([latitude, longitude]);
      setIsGeoLoading(false);
    };

    const onLocationFetchFailure = () => {
      setPositionActive(DEFAULT_POSITION);
      setPosition(DEFAULT_POSITION);
      setIsGeoLoading(false);
    };
    if (!isMobile && !isSafari) {
      navigator.permissions.query({ name: "geolocation" }).then(res => {
        const result = res;

        navigator.geolocation.getCurrentPosition(
          onLocationFetchSuccess,
          onLocationFetchFailure
        );

        if (result.state === "denied") {
          onLocationFetchFailure();
        }

        result.onchange = () => {
          if (result.state === "denied") {
            onLocationFetchFailure();
          }
        };
      });
    } else {
      navigator.geolocation.getCurrentPosition(
        onLocationFetchSuccess,
        onLocationFetchFailure
      );
    }
  };

  useEffect(() => {
    handleGeo();
  }, []);

  useEffect(() => {
    if (positionActive.length > 0) {
      setFieldValue("geo", positionActive);
      getSoilInfo();
    }
  }, [positionActive]);

  const changePosition = ({ latlng }) => {
    const { lat, lng } = latlng;
    setPosition([lat, lng]);
  };

  return (
    <div className="regagri-location" id="lat">
      <RegagriFormControl variant="small">
        <RegagriLabel
          className="headline"
          label={t("regagri:cultivationDetails.form.location.title")}
          // eslint-disable-next-line react/no-children-prop
          children={
            <RegagriTooltip
              content={t("regagri:cultivationDetails.form.location.tooltip")}
            />
          }
        />
      </RegagriFormControl>
      <RegagriFormControl variant="small" alignLeft>
        <div className="name-container" id="long">
          <RegagriLabel
            label={t(
              "regagri:cultivationDetails.form.location.inputName.label"
            )}
          />
          <RegagriTextField
            name="name"
            placeholder={t(
              "regagri:cultivationDetails.form.location.inputName.placeholder"
            )}
          />
          {positionActive.length !== 0 && (
            <>
              <div className="coords">
                {`(${positionActive[0].toFixed(5)} ${positionActive[1].toFixed(
                  5
                )})`}
              </div>
              {!isMapVisible && (
                <div className="change">
                  <Button
                    buttonsize="small"
                    buttoncolor="underline"
                    onClick={() => {
                      setIsMapVisible(true);
                    }}
                  >
                    {t(
                      "regagri:cultivationDetails.form.location.map.buttons.3"
                    )}
                  </Button>
                </div>
              )}
            </>
          )}
          <div className="map-btn">
            <Button
              buttonsize="small"
              buttoncolor="green"
              onClick={() => {
                setIsMapVisible(true);
                handleGeo();
              }}
            >
              {t("regagri:cultivationDetails.form.location.map.buttons.0")}
              <Location />
              {isGeoLoading && <Loader />}
            </Button>
          </div>
        </div>
      </RegagriFormControl>
      {isMapVisible && (
        <div className="map">
          <h3>{t("regagri:cultivationDetails.form.location.map.title")}</h3>
          <Map center={position} zoom={13} onClick={changePosition}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={position} icon={markerIcon} />
          </Map>
          <div className="map__buttons">
            <Button
              buttonsize="small"
              buttoncolor="green"
              onClick={() => {
                setPositionActive(position);
                setIsMapVisible(false);
              }}
            >
              {t("regagri:cultivationDetails.form.location.map.buttons.1")}
            </Button>
            <Button
              buttonsize="small"
              buttoncolor="red"
              onClick={() => {
                setPosition(positionActive);
                setIsMapVisible(false);
              }}
            >
              {t("regagri:cultivationDetails.form.location.map.buttons.2")}
            </Button>
          </div>
        </div>
      )}
      <RegagriFormControl variant="small" alignLeft>
        <RegagriLabel
          label={t("regagri:cultivationDetails.form.location.farmSize.label")}
        />
        <RegagriTextField
          name="farmSize"
          adornment="ha"
          placeholder={t(
            "regagri:cultivationDetails.form.location.farmSize.placeholder"
          )}
        />
      </RegagriFormControl>
    </div>
  );
};

export default RegagriLocation;
