import * as React from "react";

const ArrowRight = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={18}
    fill="none"
    {...props}
  >
    <path
      stroke="#2E3033"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M1 1.5 8.5 9 1 16.5"
    />
  </svg>
);
export default ArrowRight;
