import * as React from "react";

const WarningIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        stroke="#F59A00"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m13.351 3.77 8.2 14.238c.574 1.004-.168 2.242-1.351 2.242H3.8c-1.182 0-1.925-1.238-1.35-2.242l8.2-14.237c.59-1.028 2.11-1.028 2.701 0ZM12 13.5V9.75"
      />
      <path
        fill="#F59A00"
        d="M12 18a1.125 1.125 0 1 0 0-2.25A1.125 1.125 0 0 0 12 18Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default WarningIcon;
