import React from "react";
import PropTypes from "prop-types";

export const RainIcon = props => {
  return (
    <svg
      width="32px"
      height="32px"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>ico/pogoda-deszcz</title>
      <desc>Created with Sketch.</desc>
      <g
        id="ico/pogoda-deszcz"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="e033"
          transform="translate(9.000000, 12.000000)"
          fill={props.color}
          fillRule="nonzero"
        >
          <path
            d="M13.125056,4.78057949 C13.007868,2.09590895 10.781296,0.00783185455 7.968784,0.00783185455 C5.478539,0.00783185455 3.369155,1.58880451 2.871106,3.82602996 L2.578136,3.82602996 C1.17188,3.82602996 0,5.13853556 0,6.68967855 C0,8.18116218 1.113286,9.31468975 2.578136,9.31468975 L12.89068,9.31468975 C14.091857,9.31468975 15.000064,8.38996989 15.000064,7.16695331 C15.000064,6.09308509 14.267639,4.92972785 13.125056,4.78057949 Z M12.89068,8.83741498 L2.578136,8.83741498 C1.376959,8.83741498 0.468752,7.91269513 0.468752,6.68967855 C0.468752,5.40700262 1.435553,4.30330473 2.578136,4.30330473 L3.251967,4.30330473 L3.281264,4.09449702 C3.662125,2.00641993 5.625024,0.485106618 7.968784,0.485106618 C10.605514,0.485106618 12.656304,2.48369469 12.656304,5.01921687 L12.656304,5.25785425 L12.89068,5.25785425 C13.857481,5.25785425 14.531312,6.27206313 14.531312,7.16695331 C14.531312,8.12150284 13.828184,8.83741498 12.89068,8.83741498 Z M3.310561,13.7294813 L5.185569,10.388558 L5.595727,10.6271953 L3.720719,13.9681187 L3.310561,13.7294813 Z M0.966801,13.7294813 L2.841809,10.388558 L3.251967,10.6271953 L1.376959,13.9681187 L0.966801,13.7294813 Z M5.654321,13.7294813 L7.529329,10.388558 L7.939487,10.6271953 L6.064479,13.9681187 L5.654321,13.7294813 Z M7.998081,13.7294813 L9.873089,10.388558 L10.283247,10.6271953 L8.408239,13.9681187 L7.998081,13.7294813 Z M10.341841,13.7294813 L12.216849,10.388558 L12.627007,10.6271953 L10.751999,13.9681187 L10.341841,13.7294813 Z"
            id="Shape"
          />
        </g>
      </g>
    </svg>
  );
};

RainIcon.propTypes = {
  color: PropTypes.string,
};

RainIcon.defaultProps = {
  color: "#2E3033",
};
