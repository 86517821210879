import React from "react";
import {
  Hero,
  Benefits,
  BenefitsTable,
  Disclaimer,
  Logos,
  ContactForm,
  InvestmentCalculatorPlaceholder,
} from "agronomist-ui-components";

import PageTemplate from "../helpers/PageTemplate";
import { getBreadcrumbs } from "../helpers/fakeBreadcrumbs";
import TempShareButtonsComponent from "../helpers/TempShareButtonsComponent";
import { scrollToElement, onContactSubmit } from "../helpers/utils";
import CalculatorPage from "../../components/views/CalculatorPage/CalculatorPage";

import imageUrl from "../assets/images/kredyt_16.png";

const COPY = {
  HERO: {
    breadcrumbs: getBreadcrumbs("Kredyt na nawadnianie"),
    title: "Kredyt na nawadnianie",
    description:
      "Chcesz zwiększyć opłacalność produkcji inwestując w nawadnianie? Zastanawiasz się jak sfinansować to przedsięwzięcie? Mamy sprawdzone rozwiązania!",
    imageUrl,
    button: {
      title: "Sprawdź ofertę",
      onClick: () => scrollToElement("#ofert"),
    },
  },
  BENEFITS: {
    title: "Jakie są korzyści z finansowania?",
    subtitle:
      "Sfinansujemy lub zrefinansujemy wydatki na inwestycję w nawadnianie. Niezależnie od kierunku produkcji.",
    benefits: [
      {
        title: "Kompleksowe finansowanie",
        description: "Dodatkowo sfinansujemy podatek VAT",
        icon: "SquaresOverlapping",
      },
      {
        title: "Dogodny sposób spłaty",
        description:
          "Spłata kapitału dostosowana do wpływów z produkcji oraz możliwa karencja w spłacie kapitału do 2 lat",
        icon: "MoneyPlus",
      },
      {
        title: "Długi okres kredytowania",
        description: "Do 15 lat",
        icon: "Calendar",
      },
    ],
  },
  BENEFITS2: {
    title: "Jak to działa?",
    subtitle:
      "Kredytomat to prosta i przyjazna wyszukiwarka oferty bankowej ułatwiająca dobór produktów najbardziej dopasowanych do Twoich potrzeb związanych z prowadzeniem gospodarstwa rolnego.",
    benefits: [
      {
        title: "Znajdź produkt spełniający Twoje oczekiwania",
        description:
          "Ustaw interesujące Cię kryteria wyszukiwania, aby otrzymać ofertę jak najbardziej dopasowaną do Twoich potrzeb.",
        icon: "SearchBulb",
      },
      {
        title: "Skontaktuj się z nami",
        description:
          "Zapisz propozycję oferty i pozostaw swoje dane kontaktowe lub odwiedź nas w Oddziale.",
        icon: "MessagesBulb",
      },
      {
        title: "Gotowe! Możesz realizować swoje potrzeby",
        description:
          "Ciesz się ze zrealizowanej inwestycji w Twoim gospodarstwie!",
        icon: "HandBulb",
      },
    ],
    withArrow: true,
  },
  DISCLAIMER: {
    notes: [
      "<sup>1</sup>Zabezpieczenie dostępne tylko dla podmiotów spełniających warunki określone w dokumencie: „Warunki uzyskania w Banku Gospodarstwa Krajowego gwarancji spłaty kredytu i dopłaty do oprocentowania kredytu objętego gwarancją w ramach portfelowej linii gwarancyjnej FGR”, przekazywanym w momencie wnioskowania o gwarancję.",
      "Niniejszy materiał ma charakter wyłącznie reklamowy i informacyjny oraz nie stanowi oferty w rozumieniu Kodeksu Cywilnego. Ostateczna decyzja o udzieleniu kredytu i warunkach kredytowania uzależniona jest od wewnętrznych przepisów Banku oraz wyniku przeprowadzonej przez Bank analizy zdolności kredytowej Klienta. Świadczenie usług i produktów bankowych następuje na warunkach szczegółowo określonych w umowie zawartej z Klientem. Informacja o produktach i usługach bankowych dostępna jest w regulaminach, taryfie prowizji i opłat, dostępnych w oddziałach Banku oraz na stronie <a href='http://www.bnpparibas.pl'>www.bnpparibas.pl</a>.",
    ],
  },
  BENEFITS_TABLE: {
    footer: {
      text: "Możliwość dodatkowego ubezpieczenia Twojego sprzętu rolniczego",
      link: "/kredytomat/ubezpieczenie-maszyn-rolniczych",
    },
    packages: [
      "Przewagi produktu",
      "Maksymalny okres finansowania",
      "Maksymalna kwota kredytu",
      "Minimalny wkład własny",
      "Zabezpieczenie",
      "Karencja w spłacie kapitałuu",
      "Częstotliwość spłaty kapitału",
    ],
    features: [
      {
        // isRecommended: true,
        title: "Agro Progres",
        list: [
          "Bez biznesplanu, refinansowanie poniesionych wydatków",
          "15 lat",
          "Brak",
          "10%",
          "Hipoteka, zastaw rejestrowy,  gwarancja z Funduszu Gwarancji Rolnych<sup>1</sup>",
          "Do 2 lat",
          "Raty miesięczne lub kwartalne lub półroczne lub roczne",
        ],
        footer: {
          text: "ZAPYTAJ O OFERTĘ",
          onClick: () => {
            window.location.href = `/kredytomat/kontakt?product_url=${
              window.location.pathname
            }&product_name=Agro Progres`;
          },
        },
      },
      // {
      //   title: "Kredyt preferencyjny linia RR",
      //   list: [
      //     "Niskie oprocentowanie",
      //     "15 lat",
      //     "8 mln PLN",
      //     "20%",
      //     "Hipoteka na nieruchomości",
      //     "Do 2 lat",
      //     "Raty miesięczne lub kwartalne lub półroczne lub roczne",
      //   ],
      //   footer: {
      //     text: "ZAPYTAJ O OFERTĘ",
      //     onClick: () => {
      //       window.location.href = `/kredytomat/kontakt?product_url=${window.location.pathname}&product_name=x`;
      //     },
      //   },
      // },
    ],
  },
};

const KredytyNaNawadnianieBezDotacji = () => (
  <PageTemplate
    meta={{
      name: "Kredytomat",
      description:
        "Potrzebujesz finansowania, dotknęła Cię klęska żywiołowa, a może szukasz ubezpieczenia dla swojego gospodarstwa rolnego? Pomożemy Ci - Agronomist",
      mediaCoverUrl: imageUrl,
    }}
  >
    <Hero {...COPY.HERO} />
    <Benefits {...COPY.BENEFITS} pt="4rem" pb="4rem" />
    <BenefitsTable id="ofert" {...COPY.BENEFITS_TABLE} />
    <TempShareButtonsComponent />
    <Logos />
    <InvestmentCalculatorPlaceholder>
      <CalculatorPage isSeparate />
    </InvestmentCalculatorPlaceholder>
    <Benefits {...COPY.BENEFITS2} pt="4rem" pb="4rem" />
    <Disclaimer {...COPY.DISCLAIMER} />
    <ContactForm onSubmit={onContactSubmit} />
  </PageTemplate>
);

export default KredytyNaNawadnianieBezDotacji;
