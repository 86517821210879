import React from "react";
import "./Reportlogo.scss";

export const ReportLogo = () => {
  return (
    <div className="agroraport__logo">
      <svg
        version="1.1"
        id="Warstwa_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1721 1163.6"
      >
        <path
          fill="#00965E"
          d="M607.7,275.1l1.6-3.2c20.1-40.6,49.7-76.9,85.5-105c36.3-28.4,79.1-48.6,123.9-58.2l3.4-0.7l18.3,72.2l-3.7,0.8 c-34.4,7-67.4,22.2-95.3,43.9c-27.5,21.4-50.2,49.2-65.6,80.4l-1.6,3.2L607.7,275.1z"
        />
        <path
          fill="#4A4A49"
          d="M862.7,680.8v-74.4h3.6c127.2,0,230.7-103.5,230.7-230.7v-3.6h74.4v3.6c0,168.3-136.9,305.2-305.2,305.2 L862.7,680.8L862.7,680.8z"
        />
        <path
          fill="#4A4A49"
          d="M606.3,118.7l2.6-2.4C681.1,48.2,775.4,10.6,874.4,10.6c6,0,12.3,0.2,18.7,0.5l3.5,0.2l-2.7,74.4l-3.6-0.2 c-5.3-0.3-10.7-0.4-15.9-0.4c-80.3,0-156.7,30.5-215.1,86l-2.6,2.5L606.3,118.7z"
        />
        <path
          fill="#4A4A49"
          d="M871.6,774.2c-83.8,0-163.6-26.5-230.8-76.6c-32.3-24.1-60.6-52.9-84-85.7c-23.8-33.2-42-69.7-54.3-108.4 l-1-3.2l3.1-1.2c27-10.9,48-19.4,62.6-25.5l3.7-1.5l1.1,3.8c18.8,63.3,58.3,120.2,111.2,160.4c54.7,41.5,119.9,63.4,188.5,63.4h3.6 v74.4L871.6,774.2L871.6,774.2z"
        />
        <path
          fill="#00965E"
          d="M995.2,673.9l3.4-1.5c47.4-21.2,89.2-54.2,121-95.5l2.2-2.8l58.7,45.7l-2.2,2.8 c-38.6,50.1-89.3,90.4-146.6,116.6l-3.1,1.4L995.2,673.9z"
        />
        <path
          fill="#00965E"
          d="M1066.1,743.2l2.9-1.6c100-54.9,171.4-150,195.9-260.9l0.8-3.8l71.3,22.4l-0.8,3.2 c-30.7,131.4-116.9,243.3-236.5,307.1l-3.4,1.8L1066.1,743.2z"
        />
        <path
          fill="#00965E"
          d="M1190.6,317.1l-0.7-2.7c-18.1-73.9-62.6-138.7-125.5-182.5l-3.1-2.2l46.9-58.1l2.7,2 c39.5,28.3,73,63.2,99.6,103.9c27.1,41.5,45.7,87,55.3,135.3l0.8,4.3H1190.6z"
        />
        <path
          fill="#00965E"
          d="M675.5,821.9c-70.7-31.8-132.9-81.4-180.1-143.3l-0.5-0.7l51.5-46.7l0.6,0.8c40.7,55,94.9,98.9,156.9,126.8 l0.8,0.4l-28.4,63.1L675.5,821.9z"
        />
        <path
          fill="#00965E"
          d="M673.4,826.5c-71.1-32-133.7-81.9-181.1-144.1l-2-2.6l55.4-50.3l2.4,3.2c40.4,54.7,94.3,98.3,155.9,126l3.3,1.5 l-30.5,67.9L673.4,826.5z"
        />
        <circle fill="none" cx="875" cy="390.9" r="715" />
        <g>
          <path
            fill="#4A4A49"
            d="M92.4,1123.6H48.2l-7.3,26.3H11.3l42-144.8h39.1l41.5,144.8h-33.8L92.4,1123.6z M53,1095.8h31.3L68.1,1040 L53,1095.8z"
          />
          <path
            fill="#4A4A49"
            d="M137.5,1077.7c0-50.2,20.7-75.3,62-75.3c26,0,41,6.3,45.1,18.8s6.2,22.5,6.2,30l-31.2,2.7 c-0.5-14.2-7.6-21.3-21.1-21.3c-9,0-15.9,3.3-20.6,10s-7.1,17.9-7.1,33.6c0,25.8,3.4,40,10.3,42.6s12.5,3.9,17,3.9 c7.8,0,15-2.6,21.7-7.9V1098h-22.7v-27.9h55.6v79.8h-29.6v-8.7c-7.7,7.7-18.3,11.6-31.5,11.6c-24.8,0-40-9-45.6-26.9 C140.3,1108.1,137.5,1092,137.5,1077.7z"
          />
          <path
            fill="#4A4A49"
            d="M312.4,1150h-32.9v-144.8h43.9c17.1,0,30.3,3.3,39.7,9.8c9.4,6.5,14.1,18.1,14.1,34.7s-2.7,27.3-8.1,32.2 c-5.4,4.9-9.7,8.2-12.7,9.8l25.8,58.3h-35.8l-21.4-50.4c-5.2,0-9.4-0.2-12.6-0.6L312.4,1150L312.4,1150z M318.9,1070.6h4.3 c13.8,0,20.6-6.3,20.6-19c0-6.8-1.6-11.4-4.8-13.9c-3.2-2.4-8.3-3.6-15.3-3.6h-11.4v35.5C316.6,1070.3,318.8,1070.6,318.9,1070.6z"
          />
          <path
            fill="#4A4A49"
            d="M426.8,1006.3c7.2-2.6,14.9-3.9,23.1-3.9c8.2,0,15.8,1.3,22.7,3.9s12.9,6.9,18,12.9c5,6,9,13.8,12,23.3 c3,9.6,4.4,24.3,4.4,44.4c0,20-5.2,36-15.6,48c-10.4,12-24.4,18-41.8,18c-27.4,0-44-9-49.7-27.1c-5.8-18.1-8.6-34.1-8.6-48 s1.5-25.7,4.5-35.2c3-9.6,7.2-17.3,12.4-23.3C413.4,1013.3,419.7,1008.9,426.8,1006.3z M464.5,1036.7c-6.1-2.7-11-4.1-14.6-4.1 s-7.1,0.7-10.2,2c-3.2,1.3-5.9,3.7-8.2,7c-2.3,3.4-4,8-5.4,13.8c-1.3,5.9-2,15.1-2,27.9s2.2,22.5,6.6,29.3 c4.4,6.8,10.7,10.2,18.8,10.2s14.2-3.3,18.2-9.8c4-6.6,6-20.2,6-41C473.7,1051.1,470.6,1039.4,464.5,1036.7z"
          />
          <path
            fill="#4A4A49"
            d="M561.3,1150h-32.9v-144.8h43.9c17.1,0,30.3,3.3,39.7,9.8s14.1,18.1,14.1,34.7s-2.7,27.3-8.1,32.2 c-5.4,4.9-9.7,8.2-12.7,9.8l25.8,58.3h-35.8l-21.4-50.4c-5.2,0-9.4-0.2-12.6-0.6V1150z M567.9,1070.6h4.3c13.8,0,20.6-6.3,20.6-19 c0-6.8-1.6-11.4-4.8-13.9c-3.2-2.4-8.3-3.6-15.3-3.6h-11.4v35.5C565.6,1070.3,567.7,1070.6,567.9,1070.6z"
          />
          <path
            fill="#4A4A49"
            d="M713,1123.6h-44.2l-7.3,26.3h-29.6l42-144.8H713l41.5,144.8h-33.8L713,1123.6z M673.6,1095.8h31.3l-16.2-55.8 L673.6,1095.8z"
          />
          <path
            fill="#4A4A49"
            d="M811.6,1104.6c-4.4,0-8.5-0.3-12.2-1v46.3h-32.9v-144.8h43c19.5,0,33.2,3.5,41.2,10.4s12,19.4,12,37.5 s-4.4,31.2-13.2,39.4C840.7,1100.6,828.1,1104.6,811.6,1104.6z M807.7,1076.8c7.6,0,13.2-1.5,16.7-4.6s5.3-8.9,5.3-17.4 c0-8.4-1.5-14.2-4.5-17.2s-8.3-4.6-15.9-4.6h-9.9v42.9C801.5,1076.5,804.2,1076.8,807.7,1076.8z"
          />
          <path
            fill="#4A4A49"
            d="M910.8,1006.3c7.2-2.6,14.9-3.9,23.1-3.9s15.8,1.3,22.7,3.9c6.9,2.6,12.9,6.9,18,12.9c5,6,9,13.8,12,23.3 c3,9.6,4.4,24.3,4.4,44.4c0,20-5.2,36-15.6,48s-24.4,18-41.8,18c-27.4,0-44-9-49.7-27.1c-5.8-18.1-8.6-34.1-8.6-48 s1.5-25.7,4.5-35.2c3-9.6,7.2-17.3,12.4-23.3C897.4,1013.3,903.6,1008.9,910.8,1006.3z M948.5,1036.7c-6.1-2.7-11-4.1-14.6-4.1 s-7,0.7-10.2,2s-5.9,3.7-8.2,7c-2.3,3.4-4,8-5.4,13.8c-1.3,5.9-2,15.1-2,27.9s2.2,22.5,6.6,29.3s10.7,10.2,18.8,10.2 c8.1,0,14.2-3.3,18.2-9.8c4-6.6,6-20.2,6-41C957.7,1051.1,954.6,1039.4,948.5,1036.7z"
          />
          <path
            fill="#4A4A49"
            d="M1045.3,1150h-32.9v-144.8h43.9c17.1,0,30.3,3.3,39.7,9.8c9.4,6.5,14.1,18.1,14.1,34.7s-2.7,27.3-8.1,32.2 c-5.4,4.9-9.7,8.2-12.7,9.8l25.8,58.3h-35.8l-21.4-50.4c-5.2,0-9.4-0.2-12.6-0.6V1150z M1051.8,1070.6h4.3c13.8,0,20.6-6.3,20.6-19 c0-6.8-1.6-11.4-4.8-13.9c-3.2-2.4-8.3-3.6-15.3-3.6h-11.4v35.5C1049.5,1070.3,1051.7,1070.6,1051.8,1070.6z"
          />
          <path
            fill="#4A4A49"
            d="M1227.5,1005.2v29.6h-36.3V1150h-32.9v-115.2H1122v-29.6H1227.5z"
          />
          <path
            fill="#4A4A49"
            d="M1335.2,1032.6c-10,0-15.1,5.8-15.3,17.5l-32.8-4.2c0.6-12.6,4.9-23,13-31.2c8-8.2,19.7-12.3,34.9-12.3 c15.2,0,26.7,3.8,34.6,11.3s11.8,18.7,11.8,33.6c0,8.7-2,16.7-6.1,24s-9.6,14.8-16.6,22.6l-23.6,25.9h51.7v30.2h-97.5v-26l46.6-51 c8.2-8.9,12.3-17,12.3-24.4c0-5.4-1.2-9.4-3.5-12C1342.1,1033.9,1339,1032.6,1335.2,1032.6z"
          />
          <path
            fill="#4A4A49"
            d="M1446.4,1152.9c-33.3,0-50-25-50-74.9c0-25.7,4.5-44.7,13.5-57.1s21.5-18.5,37.5-18.5c16.3,0,28.7,6,37.1,17.9 c8.5,11.9,12.7,31,12.7,57.1C1497.3,1127.7,1480.3,1152.9,1446.4,1152.9z M1459.8,1042.9c-2.8-6.9-7.1-10.3-13-10.3 s-10.2,3.6-13,10.9c-2.8,7.2-4.2,18.9-4.2,34.9s1.4,27.4,4.2,34.2c2.8,6.8,7.1,10.2,13,10.2s10.2-3.4,13-10.3s4.2-18.5,4.2-34.8 C1463.9,1061.3,1462.5,1049.7,1459.8,1042.9z"
          />
          <path
            fill="#4A4A49"
            d="M1515.2,1119.8h31.2v-76.5l-31.2,15.9v-33.5l40.3-20.4h23.8v114.6h29.4v30.2h-93.5L1515.2,1119.8 L1515.2,1119.8z"
          />
          <path
            fill="#4A4A49"
            d="M1660,1096.1c-13.6,0-24.4-3.7-32.4-11.1s-12-18.6-12-33.5c0-15,4.3-26.9,12.9-35.8 c8.6-8.8,20.3-13.3,35.1-13.3c16.6,0,28.9,5.8,36.7,17.4c7.9,11.6,11.8,30.3,11.8,56.1c0,25.7-4.6,45-13.9,57.8 s-21.6,19.2-37.1,19.2c-17.8,0-33.2-6.4-46-19.3l13.7-26.2c3.9,4.4,8.5,8.1,13.9,11s10.4,4.3,15,4.3c8.3,0,13.9-2.6,16.8-7.8 c2.9-5.2,4.3-12.6,4.3-22.3C1673,1094.9,1666.7,1096.1,1660,1096.1z M1652.6,1037.5c-2.5,3.3-3.7,9.1-3.7,17.3 c0,8.2,5.3,12.3,15.8,12.3c5.3,0,10.4-1.3,15.3-3.9c-0.3-11.4-1.7-19.4-4.1-23.9s-6.3-6.7-11.6-6.7 C1659,1032.6,1655.1,1034.2,1652.6,1037.5z"
          />
        </g>
      </svg>
    </div>
  );
};
