import React from "react";
import {
  Hero,
  Benefits,
  BenefitsTable,
  Disclaimer,
  Logos,
  ContactForm,
  InvestmentCalculatorPlaceholder,
} from "agronomist-ui-components";

import PageTemplate from "../helpers/PageTemplate";
import { getBreadcrumbs } from "../helpers/fakeBreadcrumbs";
import TempShareButtonsComponent from "../helpers/TempShareButtonsComponent";
import { scrollToElement, onContactSubmit } from "../helpers/utils";
import CalculatorPage from "../../components/views/CalculatorPage/CalculatorPage";

import fileA from "../../assets/prf/pdf/2021 04 20  INFORMACJA O AGENCIE - zmiana kapitału.pdf";
import fileB from "../../assets/prf/pdf/OWU BNPP_CASHCPI_3_3.3.2020 _Cardif_Rzeczówka Light.pdf";
import fileC from "../../assets/prf/pdf/Karta Produktu_BNPP_CASHCPI_3_3. 3.2020_Cardif_Rzeczówka Light.pdf";

import imageUrl from "../assets/images/kredyt_1.png";

const COPY = {
  HERO: {
    breadcrumbs: getBreadcrumbs(
      "Kredyt na zakup i montaż instalacji fotowoltaicznych"
    ),
    title: "Kredyt na zakup i montaż instalacji fotowoltaicznych",
    description:
      "Chciałbyś kupić i zainstalować instalację fotowoltaiczną i wykorzystywać wyprodukowaną energię w Twoim gospodarstwie rolnym?",
    imageUrl,
    button: {
      title: "Sprawdź ofertę",
      onClick: () => scrollToElement("#ofert"),
    },
  },
  BENEFITS: {
    title: "Jakie są korzyści z finansowania?",
    subtitle:
      "Jeśli chcesz założyć panele fotowoltaiczne w swoim gospodarstwie, ale brakuje Ci środków, skorzystaj z naszej oferty na sfinansowanie tej inwestycji.",
    benefits: [
      {
        title: "Nie wymagany biznesplan",
        icon: "PaperBookmark",
      },
      {
        title: "Wysoka kwota kredytu",
        icon: "MoneyPlus",
      },
      {
        title: "Długi okres kredytowania",
        icon: "PaperShield",
      },
    ],
  },
  BENEFITS2: {
    title: "Jak to działa?",
    subtitle:
      "Kredytomat to prosta i przyjazna wyszukiwarka oferty bankowej ułatwiająca dobór produktów najbardziej dopasowanych do Twoich potrzeb związanych z prowadzeniem gospodarstwa rolnego.",
    benefits: [
      {
        title: "Znajdź produkt spełniający Twoje oczekiwania",
        description:
          "Ustaw interesujące Cię kryteria wyszukiwania, aby otrzymać ofertę jak najbardziej dopasowaną do Twoich potrzeb.",
        icon: "SearchBulb",
      },
      {
        title: "Skontaktuj się z nami",
        description:
          "Zapisz propozycję oferty i pozostaw swoje dane kontaktowe lub odwiedź nas w Oddziale.",
        icon: "MessagesBulb",
      },
      {
        title: "Gotowe! Możesz realizować swoje potrzeby",
        description:
          "Ciesz się ze zrealizowanej inwestycji w Twoim gospodarstwie!",
        icon: "HandBulb",
      },
    ],
    withArrow: true,
  },
  DOWNLOAD_DOCUMENTS: {
    title: "Dokumenty do pobrania",
    files: [
      {
        name: "Informacja o Agencie",
        details: "Produkt 2021, Plik .pdf 0.3mb",
        url: fileA,
      },
      {
        name: "OWU Cardif Rzeczówka Light",
        details: "Produkt 2021, Plik .pdf 0.2mb",
        url: fileB,
      },
      {
        name: "Karta Produktu",
        details: "Produkt 2021, Plik .pdf 0.1mb",
        url: fileC,
      },
    ],
  },
  DISCLAIMER: {
    notes: [
      "<sup>1</sup>Zabezpieczenie dostępne tylko dla podmiotów spełniających warunki określone w dokumencie: „Warunki uzyskania w Banku Gospodarstwa Krajowego gwarancji spłaty kredytu i dopłaty do oprocentowania kredytu objętego gwarancją w ramach portfelowej linii gwarancyjnej FGR”, przekazywanym w momencie wnioskowania o gwarancję.",
      "Niniejszy materiał ma charakter wyłącznie reklamowy i informacyjny oraz nie stanowi oferty w rozumieniu Kodeksu Cywilnego. Ostateczna decyzja o udzieleniu kredytu i warunkach kredytowania uzależniona jest od wewnętrznych przepisów Banku oraz wyniku przeprowadzonej przez Bank analizy zdolności kredytowej Klienta. Świadczenie usług i produktów bankowych następuje na warunkach szczegółowo określonych w umowie zawartej z Klientem. Informacja o produktach i usługach bankowych dostępna jest w regulaminach, taryfie prowizji i opłat, dostępnych w oddziałach Banku oraz na stronie <a href='http://www.bnpparibas.pl'>www.bnpparibas.pl</a>.",
    ],
  },
  BENEFITS_TABLE: {
    // footer: {
    //   text: "Instalację fotowoltaiczną możesz również ubezpieczyć",
    //   link: "/kredytomat/ubezpieczenie-odnawialnych-zrodel-energii",
    // },
    packages: [
      "Przewagi produktu",
      "Maksymalny okres finansowania",
      "Maksymalna kwota kredytu",
      "Minimalny wkład własny",
      "Zabezpieczenie",
      "Karencja w spłacie kapitału",
      "Częstotliwość spłaty kapitału",
    ],
    features: [
      {
        isRecommended: true,
        title: "Pożyczka Zwykła Fotowoltaika",
        list: [
          "Proste zabezpieczenie",
          "7 lat",
          "Od 55% do 100% kwoty inwestycji brutto",
          "0%",
          "Zastaw rejestrowy, weksel",
          "Brak",
          "Raty miesięczne lub kwartalne lub półroczne lub roczne",
        ],
        footer: {
          text: "ZAPYTAJ O OFERTĘ",
          onClick: () => {
            window.location.href = `/kredytomat/kontakt?product_url=${
              window.location.pathname
            }&product_name=Pożyczka Zwykła Fotowoltaika`;
          },
        },
      },
      {
        title: "Agro lider",
        subtitle: "dla mniejszych gospodarstw rolnych",
        list: [
          "Finansowanie kwoty brutto inwestycji",
          "10 lat",
          "300 000 PLN",
          "0%",
          "Hipoteka, zastaw rejestrowy, gwarancja FGR1 <sup>1</sup>",
          "Brak",
          "Raty miesięczne lub kwartalne",
        ],
        footer: {
          text: "ZAPYTAJ O OFERTĘ",
          onClick: () => {
            window.location.href = `/kredytomat/kontakt?product_url=${
              window.location.pathname
            }&product_name=Agro lider`;
          },
        },
      },
      {
        title: "Agro Progres",
        list: [
          "Długi okres kredytowania",
          "15 lat",
          "Brak",
          "10%",
          "Zastaw rejestrowy,  gwarancja z Funduszu Gwarancji Rolnych",
          "Do 2 lat",
          "Raty miesięczne lub kwartalne lub półroczne lub roczne",
        ],
        footer: {
          text: "ZAPYTAJ O OFERTĘ",
          onClick: () => {
            window.location.href = `/kredytomat/kontakt?product_url=${
              window.location.pathname
            }&product_name=Agro Progres`;
          },
        },
      },
    ],
  },
};

const KredytyNaInstalacjePVPozyczka = () => (
  <PageTemplate
    meta={{
      name: "Kredytomat",
      description:
        "Potrzebujesz finansowania, dotknęła Cię klęska żywiołowa, a może szukasz ubezpieczenia dla swojego gospodarstwa rolnego? Pomożemy Ci - Agronomist",
      mediaCoverUrl: imageUrl,
    }}
  >
    <Hero {...COPY.HERO} />
    <Benefits {...COPY.BENEFITS} pt="4rem" pb="4rem" />
    <BenefitsTable id="ofert" {...COPY.BENEFITS_TABLE} />
    <TempShareButtonsComponent />
    <Logos />
    <InvestmentCalculatorPlaceholder>
      <CalculatorPage isSeparate />
    </InvestmentCalculatorPlaceholder>
    <Benefits {...COPY.BENEFITS2} pt="4rem" pb="4rem" />
    {/* <DownloadDocuments {...COPY.DOWNLOAD_DOCUMENTS} /> */}
    <Disclaimer {...COPY.DISCLAIMER} />
    <ContactForm onSubmit={onContactSubmit} />
  </PageTemplate>
);

export default KredytyNaInstalacjePVPozyczka;
