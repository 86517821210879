import React from "react";
import {
  Hero,
  Benefits,
  BenefitsTable,
  Logos,
  Disclaimer,
  ContactForm,
} from "agronomist-ui-components";

import PageTemplate from "../helpers/PageTemplate";
import { getBreadcrumbs } from "../helpers/fakeBreadcrumbs";
import TempShareButtonsComponent from "../helpers/TempShareButtonsComponent";
import { scrollToElement, onContactSubmit } from "../helpers/utils";

import imageUrl from "../assets/images/kredyt_13.png";

const COPY = {
  HERO: {
    breadcrumbs: getBreadcrumbs("Kredyt na prowadzenie gospodarstwa"),
    title: "Kredyt na prowadzenie gospodarstwa",
    description:
      "Potrzebujesz finansowania potrzeb związanych m.in. z zakupem środków do produkcji rolnej jak nawozy, środki ochrony roślin lub chcesz rozwinąć potencjał swojej działalności rolniczej?",
    imageUrl,
    button: {
      title: "Sprawdź ofertę",
      onClick: () => scrollToElement("#ofert"),
    },
  },
  BENEFITS: {
    title: "Jakie są korzyści z finansowania?",
    subtitle:
      "Kredyt obrotowy odnawialny to swobodny dostęp do pieniędzy. Bieżące wpływy na rachunek kredytowy zmniejszają kwotę zadłużenia i pozwalają na jej ponowne wykorzystanie.",
    benefits: [
      {
        title: "Możliwość dopłaty do oprocentowania do 2%",
        icon: "HandCoins",
      },
      {
        title:
          "Możliwość zabezpieczenia kredytu wyłącznie bezpłatną gwarancją z Funduszu Gwarancji Rolnych (FGR)",
        icon: "Umbrella",
      },
      {
        title: "Indywidualnie dopasowane terminy uruchomienia i spłaty kredytu",
        icon: "Calendar",
      },
    ],
  },
  BENEFITS2: {
    title: "Jak to działa?",
    subtitle:
      "Kredytomat to prosta i przyjazna wyszukiwarka oferty bankowej ułatwiająca dobór produktów najbardziej dopasowanych do Twoich potrzeb związanych z prowadzeniem gospodarstwa rolnego.",
    benefits: [
      {
        title: "Znajdź produkt spełniający Twoje oczekiwania",
        description:
          "Ustaw interesujące Cię kryteria wyszukiwania, aby otrzymać ofertę jak najbardziej dopasowaną do Twoich potrzeb.",
        icon: "SearchBulb",
      },
      {
        title: "Skontaktuj się z nami",
        description:
          "Zapisz propozycję oferty i pozostaw swoje dane kontaktowe lub odwiedź nas w Oddziale.",
        icon: "MessagesBulb",
      },
      {
        title: "Gotowe! Możesz realizować swoje potrzeby",
        description:
          "Ciesz się z dodatkowych środków na finansowanie potrzeb Twojego gospodarstwa!",
        icon: "HandBulb",
      },
    ],
    withArrow: true,
  },
  DISCLAIMER: {
    notes: [
      "<sup>1</sup>Kredyt dostępny tylko dla podmiotów spełniających warunki określone w dokumencie: „Warunki uzyskania w Banku Gospodarstwa Krajowego gwarancji spłaty kredytu i dopłaty do oprocentowania kredytu objętego gwarancją w ramach portfelowej linii gwarancyjnej FGR”, przekazywanym w momencie wnioskowania o gwarancję. Dopłata do oprocentowania realizowana jest przez BGK, w wysokości odsetek kredytowych, lecz nie więcej niż 2% w stosunku rocznym (przez pierwsze 12 miesięcy spłaty kredytu przez kredytobiorcę).”",
      "Niniejszy materiał ma charakter wyłącznie reklamowy i informacyjny oraz nie stanowi oferty w rozumieniu Kodeksu Cywilnego. Ostateczna decyzja o udzieleniu kredytu i warunkach kredytowania uzależniona jest od wewnętrznych przepisów Banku oraz wyniku przeprowadzonej przez Bank analizy zdolności kredytowej Klienta. Świadczenie usług i produktów bankowych następuje na warunkach szczegółowo określonych w umowie zawartej z Klientem. Informacja o produktach i usługach bankowych dostępna jest w regulaminach, taryfie prowizji i opłat, dostępnych w oddziałach Banku oraz na stronie <a href='http://www.bnpparibas.pl'>www.bnpparibas.pl</a>.",
    ],
  },
  BENEFITS_TABLE: {
    packages: [
      "Przewagi produktu",
      "Maksymalny okres finansowania",
      "Maksymalna kwota kredytu",
      "Zabezpieczenie",
      "Częstotliwość spłaty kapitału",
    ],
    features: [
      {
        title: "Kredyt obrotowy z gwarancją FGR i dopłatą<sup>1</sup>",
        subtitle: "odnawialny",
        isRecommended: true,
        list: [
          "Dopłata do oprocentowania przez pierwsze 12 miesięcy",
          "3 lata",
          "Równowartość w PLN kwoty 200 000 EUR",
          "Hipoteka, zastaw rejestrowy, gwarancja FGR",
          "Spłata jednorazowa",
        ],
        footer: {
          text: "ZAPYTAJ O OFERTĘ",
          onClick: () => {
            window.location.href = `/kredytomat/kontakt?product_url=${
              window.location.pathname
            }&product_name=Kredyt obrotowy z gwarancją FGR i dopłatą`;
          },
        },
      },
      {
        title: "Rzeczówka Zabezpieczona",
        subtitle: "odnawialna",
        list: [
          "Możliwość refinansowania zobowiązań",
          "3 lata",
          "Brak",
          "Hipoteka, zastaw rejestrowy",
          "Spłata jednorazowa",
        ],
        footer: {
          text: "ZAPYTAJ O OFERTĘ",
          onClick: () => {
            window.location.href = `/kredytomat/kontakt?product_url=${
              window.location.pathname
            }&product_name=Rzeczówka Zabezpieczona`;
          },
        },
      },
    ],
  },
};

const KredytyObrotoweNieSplacaneWRatach = () => (
  <PageTemplate
    meta={{
      name: "Kredytomat",
      description:
        "Potrzebujesz finansowania, dotknęła Cię klęska żywiołowa, a może szukasz ubezpieczenia dla swojego gospodarstwa rolnego? Pomożemy Ci - Agronomist",
      mediaCoverUrl: imageUrl,
    }}
  >
    <Hero {...COPY.HERO} />
    <Benefits {...COPY.BENEFITS} pt="4rem" pb="4rem" />
    <BenefitsTable id="ofert" {...COPY.BENEFITS_TABLE} />
    <TempShareButtonsComponent />
    <Logos />
    <Benefits {...COPY.BENEFITS2} pt="4rem" pb="4rem" />
    <Disclaimer {...COPY.DISCLAIMER} />
    <ContactForm onSubmit={onContactSubmit} />
  </PageTemplate>
);

export default KredytyObrotoweNieSplacaneWRatach;
