import React from "react";

export const FacebookIcon = () => {
  return (
    <div className="facebook">
      <svg
        width="32px"
        height="32px"
        viewBox="0 0 32 32"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <title>ico/social/fb</title>
        <desc>Created with Sketch.</desc>
        <g
          id="ico/social/fb"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <path
            d="M17.0922542,32.0000707 L1.76615687,32.0000707 C0.790448248,32.0000707 8.52651283e-14,31.2091291 8.52651283e-14,30.2338005 L8.52651283e-14,1.76614353 C8.52651283e-14,0.790568248 0.790574914,7.10542736e-15 1.76615687,7.10542736e-15 L30.2340138,7.10542736e-15 C31.2093491,7.10542736e-15 32.0000507,0.790568248 32.0000507,1.76614353 L32.0000507,30.2338005 C32.0000507,31.2092558 31.2092291,32.0000707 30.2340138,32.0000707 L22.0795308,32.0000707 L22.0795308,19.6079125 L26.2390191,19.6079125 L26.8618431,14.7784562 L22.0795335,14.7784562 L22.0795335,11.6951567 C22.0795335,10.2969206 22.4678056,9.34406535 24.4728649,9.34406535 L27.0302167,9.34294735 L27.0302167,5.02344538 C26.5879325,4.96458993 25.0698595,4.83309767 23.3037026,4.83309767 C19.6165486,4.83309767 17.0922235,7.08374217 17.0922235,11.2168704 L17.0922235,14.7784642 L12.9220618,14.7784642 L12.9220618,19.6079205 L17.0922235,19.6079205 L17.0922235,32.0000787 L17.0922542,32.0000707 Z"
            fill="#4460A0"
          />
        </g>
      </svg>
    </div>
  );
};
