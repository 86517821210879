import React from "react";
import {
  Hero,
  Benefits,
  BenefitsTable,
  DownloadDocuments,
  Disclaimer,
  ContactForm,
} from "agronomist-ui-components";

import PageTemplate from "../helpers/PageTemplate";
import { getBreadcrumbs } from "../helpers/fakeBreadcrumbs";
import TempShareButtonsComponent from "../helpers/TempShareButtonsComponent";
import { scrollToElement, onContactSubmit } from "../helpers/utils";

import imageUrl from "../assets/images/kredyt_22.png";
import fileA from "../../assets/prf/pdf/INFORMACJA O AGENCIE - fuzja Generali-Concordia.pdf";
import fileB from "../../assets/prf/pdf/Karta Produktu_BGZBNPP_REVOLVINGCPI_3_3.2_2018_Cardif_Agro Ekspres.pdf";
import fileC from "../../assets/prf/pdf/OWU_BGZBNPP_REVOLVINGCPI_3_3.2_2018_Cardif_Agro Ekspres.pdf";

const COPY = {
  HERO: {
    breadcrumbs: getBreadcrumbs(
      "Kredyt na bieżące wydatki gospodarstwa rolnego"
    ),
    title: "Kredyt na bieżące wydatki gospodarstwa rolnego",
    description:
      "Potrzebujesz środków na bieżące wydatki związane z prowadzeniem gospodarstwa rolnego lub sfinansowanie rozliczeń pieniężnych z kontrahentami?",
    imageUrl,
    button: {
      title: "Sprawdź ofertę",
      onClick: () => scrollToElement("#ofert"),
    },
  },
  BENEFITS: {
    title: "Jakie są korzyści z finansowania?",
    subtitle:
      "Kredyt obrotowy w rachunku bieżącym to elastyczna forma uzupełnienia brakujących środków finansowych gospodarstwa.",
    benefits: [
      {
        title: "Pełna swoboda decydowania",
        description: "Przeznaczasz środki na dowolny cel",
        icon: "HandGrid",
      },
      {
        title: "Możliwość odnowienia na kolejny okres",
        description:
          "Spłacając kapitał, Twój limit powiększa się o spłaconą kwotę",
        icon: "Calendar",
      },
      {
        title: "Opcja ubezpieczenia spłaty kredytu",
        description:
          "Zabezpieczenie na wypadek śmierci, poważnego zachorowania lub pobytu w szpitalu",
        icon: "PaperShield",
      },
    ],
  },
  BENEFITS2: {
    title: "Jak to działa?",
    subtitle:
      "Kredytomat to prosta i przyjazna wyszukiwarka oferty bankowej ułatwiająca dobór produktów najbardziej dopasowanych do Twoich potrzeb związanych z prowadzeniem gospodarstwa rolnego.",
    benefits: [
      {
        title: "Znajdź produkty spełniające twoje oczekiwania",
        description:
          "Ustaw intersujące Cię kryteria wyszukiwania, aby otrzymać ofertę jak najbardziej dopasowaną do Twoich potrzeb.",
        icon: "SearchBulb",
      },
      {
        title: "Skontaktuj się z nami",
        description:
          "Zapisz propozycję oferty i pozostaw swoje dane kontaktowe lub odwiedź nas w Oddziale.",
        icon: "MessagesBulb",
      },
      {
        title: "Gotowe! Możesz realizować swoje potrzeby",
        description:
          "Ciesz się z dodatkowych środków na finansowanie potrzeb Twojego gospodarstwa!",
        icon: "HandBulb",
      },
    ],
  },
  DOWNLOAD_DOCUMENTS: {
    title: "Dokumenty do pobrania",
    files: [
      {
        name: "Informacja o Agencie",
        details: "Produkt 2021, Plik .pdf 0.5mb",
        url: fileA,
      },
      {
        name: "Karta Produktu",
        details: "Produkt 2021, Plik .pdf 0.3mb",
        url: fileB,
      },
      {
        name: "OWU Cardif Agro Ekspres",
        details: "Produkt 2021, Plik .pdf 0.4mb",
        url: fileC,
      },
    ],
  },
  DISCLAIMER: {
    notes: [
      "Niniejszy materiał ma charakter wyłącznie reklamowy i informacyjny oraz nie stanowi oferty w rozumieniu Kodeksu Cywilnego. Ostateczna decyzja o udzieleniu kredytu i warunkach kredytowania uzależniona jest od wewnętrznych przepisów Banku oraz wyniku przeprowadzonej przez Bank analizy zdolności kredytowej Klienta. Świadczenie usług i produktów bankowych oraz ochrony ubezpieczeniowej następuje na warunkach szczegółowo określonych w umowie zawartej z Klientem oraz w Ogólnych Warunkach Ubezpieczenia. Bank BNP Paribas działa jako agent ubezpieczeniowy Towarzystwa Ubezpieczeń na Życie Cardif Polska S.A. z siedzibą w Warszawie przy Pl. Piłsudskiego 2. Informacja o produktach i usługach bankowych dostępna jest w regulaminach, taryfie prowizji i opłat, dostępnych w oddziałach Banku oraz na stronie <a href='http://www.bnpparibas.pl'>www.bnpparibas.pl</a>.",
    ],
  },
  BENEFITS_TABLE: {
    packages: [
      "Przewagi produktu",
      "Maksymalny okres finansowania",
      "Maksymalna kwota kredytu",
      "Zabezpieczenie",
      "Częstotliwość spłaty kapitału",
    ],
    features: [
      {
        title: "Agro Ekspres",
        isRecommended: true,
        list: [
          "Długi okres kredytowania ze swobodnym dostępem do środków",
          "5 lat",
          "2 mln PLN",
          "Hipoteka na nieruchomości rolnej",
          "Jednorazowo lub w ratach",
        ],
        footer: {
          text: "ZAPYTAJ O OFERTĘ",
          onClick: () => {
            window.location.href = `/kredytomat/kontakt?product_url=${
              window.location.pathname
            }&product_name=Agro Ekspres`;
          },
        },
        annotation: "Możliwość ubezpieczenia spłaty kredytu CARDIF",
      },
      {
        title: "Formuła Agro",
        subtitle: "dla mniejszych gospodarstw",
        list: [
          "Brak zabezpieczeń rzeczowych, uproszczona procedura",
          "1 rok w ramach umowy na 10 lat",
          "200 tys. PLN",
          "Wpływy na rachunek bieżący",
          "Jednorazowo",
        ],
        footer: {
          text: "ZAPYTAJ O OFERTĘ",
          onClick: () => {
            window.location.href = `/kredytomat/kontakt?product_url=${
              window.location.pathname
            }&product_name=Formuła Agro`;
          },
        },
      },
    ],
  },
};

const KredytyObrotoweWRachunkuBiezacym = () => (
  <PageTemplate
    meta={{
      name: "Kredytomat",
      description:
        "Potrzebujesz finansowania, dotknęła Cię klęska żywiołowa, a może szukasz ubezpieczenia dla swojego gospodarstwa rolnego? Pomożemy Ci - Agronomist",
      mediaCoverUrl: imageUrl,
    }}
  >
    <Hero {...COPY.HERO} />
    <Benefits {...COPY.BENEFITS} pt="4rem" pb="4rem" />
    <BenefitsTable id="ofert" {...COPY.BENEFITS_TABLE} />
    <TempShareButtonsComponent />
    <Benefits {...COPY.BENEFITS2} withArrow pt="4rem" pb="4rem" />
    <DownloadDocuments {...COPY.DOWNLOAD_DOCUMENTS} />
    <Disclaimer {...COPY.DISCLAIMER} mt="2rem" mb="4rem" />
    <ContactForm onSubmit={onContactSubmit} />
  </PageTemplate>
);

export default KredytyObrotoweWRachunkuBiezacym;
