import React from "react";
import PropTypes from "prop-types";

export const ArrowIcon = props => {
  return (
    <div
      className={props.selected ? "arrowIcon--selected" : "arrowIcon"}
      onClick={props.onClick}
      role="button"
      tabIndex={0}
    >
      <svg
        width="32px"
        height="32px"
        viewBox="0 0 32 32"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <title>ico/arrow</title>
        <desc>Created with Sketch.</desc>
        <g
          id="ico/arrow"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <polygon
            id="Path"
            fill={props.color}
            fillRule="nonzero"
            points="16.0233452 21.0768932 16.0233524 21.1001076 16.0116762 21.0884968 16 21.1001076 16.0000073 21.0768932 7 12.127266 9.11535801 10 16.0116762 16.8577141 22.9079944 10 25.0233524 12.127266 16.0233452 21.0768932"
          />
        </g>
      </svg>
    </div>
  );
};

ArrowIcon.propTypes = {
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  color: PropTypes.string.isRequired,
};

ArrowIcon.defaultProps = {
  selected: false,
  onClick: () => {},
};
