import React from "react";
import {
  Hero,
  Benefits,
  BenefitsTable,
  DownloadDocuments,
  Disclaimer,
  ContactForm,
  InvestmentCalculatorPlaceholder,
} from "agronomist-ui-components";

import PageTemplate from "../helpers/PageTemplate";
import { getBreadcrumbs } from "../helpers/fakeBreadcrumbs";
import TempShareButtonsComponent from "../helpers/TempShareButtonsComponent";
import { scrollToElement, onContactSubmit } from "../helpers/utils";
import CalculatorPage from "../../components/views/CalculatorPage/CalculatorPage";

import fileA from "../../assets/prf/pdf/2021 04 20  INFORMACJA O AGENCIE - zmiana kapitału.pdf";
import fileB from "../../assets/prf/pdf/Karta Produktu_BNPP_CASHCPI_3_3. 3.2020_Cardif_Rzeczówka Light.pdf";
import fileC from "../../assets/prf/pdf/OWU BNPP_CASHCPI_3_3.3.2020 _Cardif_Rzeczówka Light.pdf";

import imageUrl from "../assets/images/kredyt_20.png";

const COPY = {
  HERO: {
    breadcrumbs: getBreadcrumbs("Kredyt na bieżące wydatki bez zabezpieczenia"),
    title: "Kredyt na bieżące wydatki bez zabezpieczenia",
    description:
      "Potrzebujesz finansowania bieżących potrzeb związanych m.in. z zakupem środków do produkcji rolnej czy sprzedażą płodów rolnych z Twojego gospodarstwa?",
    imageUrl,
    button: {
      title: "Sprawdź ofertę",
      onClick: () => scrollToElement("#ofert"),
    },
  },
  BENEFITS: {
    title: "Jakie są korzyści z finansowania?",
    subtitle:
      "Kredyt obrotowy pomoże uzupełnić środki finansowe gospodarstwa, poprawić jego działanie oraz ułatwić utrzymanie bieżącej płynności.",
    benefits: [
      {
        title: "Proste procedury i minimum formalności",
        description:
          "Szybka decyzja i brak konieczności ustanawiania zabezpieczeń",
        icon: "TickRounded",
      },
      {
        title: "Możliwość ubezpieczenia spłaty kredytu",
        description:
          "Zabezpiecz się na wypadek śmierci, poważnego zachorowania lub pobytu w szpitalu",
        icon: "PaperShield",
      },
      {
        title: "Brak konieczności dokumentowania wydatkowanych środków",
        icon: "PaperBookmark",
      },
    ],
  },
  BENEFITS2: {
    title: "Jak to działa?",
    subtitle:
      "Kredytomat to prosta i przyjazna wyszukiwarka oferty bankowej ułatwiająca dobór produktów najbardziej dopasowanych do Twoich potrzeb związanych z prowadzeniem gospodarstwa rolnego.",
    benefits: [
      {
        title: "Znajdź produkt spełniający Twoje oczekiwania",
        description:
          "Ustaw interesujące Cię kryteria wyszukiwania, aby otrzymać ofertę jak najbardziej dopasowaną do Twoich potrzeb.",
        icon: "SearchBulb",
      },
      {
        title: "Skontaktuj się z nami",
        description:
          "Zapisz propozycję oferty i pozostaw swoje dane kontaktowe lub odwiedź nas w Oddziale.",
        icon: "MessagesBulb",
      },
      {
        title: "Gotowe! Możesz realizować swoje potrzeby",
        description:
          "Ciesz się z dodatkowych środków na finansowanie potrzeb Twojego gospodarstwa!",
        icon: "HandBulb",
      },
    ],
    withArrow: true,
  },
  DOWNLOAD_DOCUMENTS: {
    title: "Dokumenty do pobrania",
    files: [
      {
        name: "Informacja o Agencie",
        details: "Produkt 2021, Plik .pdf 0.5mb",
        url: fileA,
      },
      {
        name: "Karta Produktu",
        details: "Produkt 2021, Plik .pdf 0.3mb",
        url: fileB,
      },
      {
        name: "OWU Cardif Rzeczówka Light",
        details: "Produkt 2021, Plik .pdf 0.4mb",
        url: fileC,
      },
    ],
  },
  DISCLAIMER: {
    notes: [
      "Niniejszy materiał ma charakter wyłącznie reklamowy i informacyjny oraz nie stanowi oferty w rozumieniu Kodeksu Cywilnego. Ostateczna decyzja o udzieleniu kredytu i warunkach kredytowania uzależniona jest od wewnętrznych przepisów Banku oraz wyniku przeprowadzonej przez Bank analizy zdolności kredytowej Klienta. Świadczenie usług i produktów bankowych oraz ochrony ubezpieczeniowej następuje na warunkach szczegółowo określonych w umowie zawartej z Klientem oraz w Ogólnych Warunkach Ubezpieczenia. Bank BNP Paribas działa jako agent ubezpieczeniowy Towarzystwa Ubezpieczeń na Życie Cardif Polska S.A. z siedzibą w Warszawie przy Pl. Piłsudskiego 2. Informacja o produktach i usługach bankowych dostępna jest w regulaminach, taryfie prowizji i opłat, dostępnych w oddziałach Banku oraz na stronie <a href='http://www.bnpparibas.pl'>www.bnpparibas.pl</a>.",
    ],
  },
  BENEFITS_TABLE: {
    footer: {
      text: "Możliwość wykupienia ubezpieczenia spłaty kredytu CARDIF",
    },
    packages: [
      "Przewagi produktu",
      "Maksymalny okres finansowania",
      "Maksymalna kwota kredytu",
      "Zabezpieczenie",
      "Karencja w spłacie kapitałuu",
      "Częstotliwość spłaty kapitału",
    ],
    features: [
      {
        title: "Rzeczówka Light",
        subtitle: "dla mniejszych gospodarstw",
        list: [
          "Bez zabezpieczeń, wypłacany na rachunek bieżący",
          "7 lat",
          "200 tys. PLN",
          "Brak",
          "Brak",
          "Raty miesięczne lub kwartalne",
        ],
        footer: {
          text: "ZAPYTAJ O OFERTĘ",
          onClick: () => {
            window.location.href = `/kredytomat/kontakt?product_url=${
              window.location.pathname
            }&product_name=Rzeczówka Light`;
          },
        },
      },
    ],
  },
};

const KredytyObrotoweSplacaneWRatachBezZabezpieczenia = () => (
  <PageTemplate
    meta={{
      name: "Kredytomat",
      description:
        "Potrzebujesz finansowania, dotknęła Cię klęska żywiołowa, a może szukasz ubezpieczenia dla swojego gospodarstwa rolnego? Pomożemy Ci - Agronomist",
      mediaCoverUrl: imageUrl,
    }}
  >
    <Hero {...COPY.HERO} />
    <Benefits {...COPY.BENEFITS} pt="4rem" pb="4rem" />
    <BenefitsTable id="ofert" {...COPY.BENEFITS_TABLE} />
    <TempShareButtonsComponent />
    <InvestmentCalculatorPlaceholder>
      <CalculatorPage isSeparate />
    </InvestmentCalculatorPlaceholder>
    <Benefits {...COPY.BENEFITS2} pt="4rem" pb="4rem" />
    <DownloadDocuments {...COPY.DOWNLOAD_DOCUMENTS} />
    <Disclaimer {...COPY.DISCLAIMER} />
    <ContactForm onSubmit={onContactSubmit} />
  </PageTemplate>
);

export default KredytyObrotoweSplacaneWRatachBezZabezpieczenia;
