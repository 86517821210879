import React from "react";
import {
  Hero,
  Benefits,
  Disclaimer,
  ContactForm,
  Grid,
  Button,
  List,
} from "agronomist-ui-components";

import PageTemplate from "../helpers/PageTemplate";
import { getBreadcrumbs } from "../helpers/fakeBreadcrumbs";
import TempShareButtonsComponent from "../helpers/TempShareButtonsComponent";
import { scrollToElement, onContactSubmit } from "../helpers/utils";

import imageUrl from "../assets/images/kredyt_29.png";
import imageUrl2 from "../assets/images/kredyt_mastercard.png";
import imageUrl3 from "../assets/images/kredyt_agrobox.png";

const COPY = {
  HERO: {
    breadcrumbs: getBreadcrumbs("Rachunek bieżący dla gospodarstw rolnych"),
    title: "Rachunek bieżący dla gospodarstw rolnych",
    description:
      "Jeśli szukasz nowoczesnego rachunku dla Twojego gospodarstwa z dostępem do dodatkowych usług, sprawdź naszą ofertę. Mamy doskonałe rozwiązania w tym zakresie!",
    imageUrl,
    button: {
      title: "Sprawdź ofertę",
      onClick: () => scrollToElement("#ofert"),
    },
  },
  BENEFITS: {
    title: "Korzyści z posiadania rachunku bieżącego dla gospodarstw rolnych:",
    benefits: [
      {
        title: "Bezpieczeństwo i łatwy dostęp do zgromadzonych środków",
        icon: "Shield",
      },
      {
        title: "Blisko 500 placówek bankowych oferujących usługi bankowe",
        icon: "MapMarker",
      },
      {
        title: "Wsparcie dedykowanego doradcy w sprawach bankowych",
        icon: "MessagesEllipsis",
      },
      {
        title: "Innowacyjne rozwiązania i usługi dodatkowe",
        icon: "Signposts",
      },
    ],
  },
  LIST: {
    title: "Konto otwarte na Agrobiznes",
    description:
      "Sprawdź nasze konto i dodatkowe usługi z nim związane dedykowane mniejszym gospodarstwom rolnym. To idealne rozwiązanie dla Ciebie! Co oferujemy:",
    items: [
      "od 0 zł za prowadzenie rachunku bieżącego w PLN za m-c",
      "od 0 zł za bankowość internetową, mobilną oraz telefoniczną za m-c",
      "0 zł za wydanie karty debetowej w PLN i EUR oraz multiwalutowej",
      "od 0 zł za użytkowanie karty debetowej w PLN",
      "0 zł za przelewy internetowe krajowe wewnętrzne i wychodzące w PLN i EUR",
    ],
  },
  LIST2: {
    title: "Pakiet Agro Box",
    description:
      "Sprawdź nasz pakiet Agro Box i dodatkowe usługi z nim związane dedykowane średnim i dużym gospodarstwom rolnym. To znakomite rozwiązanie dla Ciebie! Co oferujemy:",
    items: [
      "od 0 zł za prowadzenie rachunku",
      "od 0 zł za wpłaty do wpłatomatów w oddziale Banku",
      "0 zł za wypłaty z bankomatów w oddziale Banku",
      "od 0 zł za wydanie i użytkowanie karty debetowej",
      "wsparcie dedykowanych Ekspertów Agro",
    ],
  },
  BENEFITS2: {
    title: "Jak to działa?",
    subtitle:
      "Kredytomat to prosta i przyjazna wyszukiwarka oferty bankowej ułatwiająca dobór produktów najbardziej dopasowanych do Twoich potrzeb związanych z prowadzeniem gospodarstwa rolnego.",
    benefits: [
      {
        title:
          "Wybierz produkt spełniający Twoje oczekiwania oraz najbardziej dopasowany do Twoich potrzeb",
        icon: "SearchBulb",
      },
      {
        title: "Skontaktuj się z nami",
        description:
          "Masz pytania dotyczące produktu? Pozostaw swoje dane kontaktowe lub odwiedź nas w Oddziale.",
        icon: "MessagesBulb",
      },
      {
        title: "Gotowe! Możesz realizować swoje potrzeby",
        description:
          "Ciesz się nowoczesnym rachunkiem dla Twojego gospodarstwa!",
        icon: "HandBulb",
      },
    ],
    withArrow: true,
  },
  DISCLAIMER: {
    notes: [
      "Niniejszy materiał ma charakter wyłącznie reklamowy i informacyjny oraz nie stanowi oferty w rozumieniu Kodeksu cywilnego. Szczegółowe informacje o produktach i usługach bankowych znajdują się w regulaminach, taryfie prowizji i opłat, dostępnych w oddziałach Banku oraz na stronie <a href='http://www.bnpparibas.pl'>www.bnpparibas.pl</a>.",
    ],
  },
};

const RachunekIBankowoscElektroniczna = () => (
  <PageTemplate
    meta={{
      name: "Konto bankowe dla rolnika w banku BNP",
      description:
        "Konto otwarte na agrobiznes. Sprawdź naszą ofertę bankowości i dodatkowe usługi dedykowane gospodarstwom rolnym - Agronomist",
      mediaCoverUrl: imageUrl,
    }}
  >
    <Hero {...COPY.HERO} />
    <Benefits {...COPY.BENEFITS} pt="4rem" pb="4rem" />
    <Grid.Container fluid background="#00965e" id="ofert">
      <Grid.Container py="4rem" className="list-prf">
        <Grid.Row>
          <Grid.Col lg={{ size: 3, offset: 2 }}>
            <div
              style={{
                width: "100%",
                height: "0",
                paddingTop: "100%",
                background: `no-repeat center/cover url(${imageUrl2})`,
              }}
            />
          </Grid.Col>
          <Grid.Col lg={{ size: 5, offset: 1 }}>
            <List {...COPY.LIST} isLight />
            <Button
              isPrimary
              onClick={() => {
                window.location.href = `/kredytomat/kontakt?product_url=${
                  window.location.pathname
                }&product_name=Konto bankowe dla rolnika w banku BNP`;
              }}
            >
              Zapytaj o ofertę
            </Button>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </Grid.Container>
    <Grid.Container
      fluid
      background="#ececec"
      className="list-prf list-prf--isReverse"
    >
      <Grid.Container py="4rem">
        <Grid.Row>
          <Grid.Col lg={{ size: 4, offset: 2 }}>
            <List {...COPY.LIST2} />
            <Button
              isPrimary
              onClick={() => {
                window.location.href = `/kredytomat/kontakt?product_url=${
                  window.location.pathname
                }&product_name=Konto bankowe dla rolnika w banku BNP`;
              }}
            >
              Zapytaj o ofertę
            </Button>
          </Grid.Col>
          <Grid.Col lg={{ size: 3, offset: 1 }}>
            <div
              style={{
                width: "100%",
                height: "0",
                paddingTop: "100%",
                background: `no-repeat center/cover url(${imageUrl3})`,
              }}
            />
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </Grid.Container>
    <TempShareButtonsComponent />
    <Benefits {...COPY.BENEFITS2} pt="4rem" pb="4rem" />
    <Disclaimer {...COPY.DISCLAIMER} />
    <ContactForm onSubmit={onContactSubmit} />
  </PageTemplate>
);

export default RachunekIBankowoscElektroniczna;
